<template>
    <div class="flex flex-col w-full">
        <label v-if="title" class="block text-sm font-medium mb-1 text-gray-700">{{ title }}</label>
        <div class="select-small-boxes mt-1 sm:mt-0 sm:col-span-1 relative z-0 inline-flex">
            <button v-for="(val, index) in values"
                    :key="val"
                    type="button"
                    :class="['w-16 h-full sm:text-sm leading-5 font-medium transition ease-in-out duration-150 focus:outline-none shadow-sm capitalize',
                            value === val ? 'border-blue-700 text-white bg-blue-700' : 'border-gray-300 text-gray-700 bg-white',
                            renderClasses(index)]"
                    @click.prevent="value = val">
                {{ val }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "select-small-boxes",
    props: {
        title: {
            type: String,
            default: ''
        },
        values: {
            type: Array,
            default: () => []
        },
        selectedValue: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            value: this.$props.selectedValue
        }
    },
    watch: {
        value(newVal) {
            this.$emit('on-change', newVal)
        }
    },
    methods: {
        renderClasses(index){
            let classes = '';
            if(index === 0){
                classes = 'rounded-l-md border'
            }else if (index === this.$props.values.length - 1){
                classes = "rounded-r-md border-t border-b border-r"
            }else{
                classes = "rounded-none border-t border-b border-r"
            }


            return classes;
        }
    }
}
</script>

<style scoped>

</style>